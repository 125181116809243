* {
  -webkit-print-color-adjust: exact;
}
@media print {
  @page {
    size: portrait A4;
    margin: 21cm 29.7cm;
    -webkit-print-color-adjust: exact;
  }
}

div .client-bg {
  background-color: #eeeeee !important;
  /* -webkit-print-color-adjust: exact; */
}

div .recap-bg {
  background-color: #f7f7f7 !important;
  /* -webkit-print-color-adjust: exact; */
}

section .layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
